/**
 * The matomo cookie gets set when the users accepts the "Give consent" button on the cookie banner or if the
 * statistics switch is toggled on. Similarly if the statistics is toggled off (and saved) then the consent gets revoked.
 *
 * Google Analytics cookies set only if statistics option is toggled on.
 * If statistics is toggled off, they are removed.
 *
 * cookieBanner is disabled only when cookies-feedback cookie is set to true
 */

import Cookies from 'js-cookie';
import { el, PHABLET_BREAKPOINT, selectElementFromParent } from './util/util';

const COOKIES = {
  FEEDBACK: 'cookies-feedback',
  BASICS: 'basics',
  STATISTICS: 'statistics',
};

const TAG_ID = {
  PROD: 'GTM-PMC3J2X',
  DEV: 'GTM-NHXQ7QL',
};

export default class Cookie {
  state = {
    [COOKIES.BASICS]: true,
    [COOKIES.STATISTICS]: false,
  };
  stateSwitchElements = {
    [COOKIES.STATISTICS]: undefined,
  };

  initializeCookie() {
    this.setupCookies();
    if (!this.hasGivenCookiesFeedback()) {
      this.initializeElements();
      this.handleResize();
      this.initBanner();
      this.initModal();
    }
  }

  initializeElements() {
    Object.keys(this.stateSwitchElements).forEach((switchKey) => {
      const switchElement = selectElementFromParent(el(`#${switchKey}`), 'input');
      this.stateSwitchElements[switchKey] = switchElement;
    });

    this.modal = el('.cookie-modal-wrapper');
    this.cookieBanner = el('.cookie-banner');
    this.cookieBannerText = el('#cookie-text');
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  setupCookies() {
    const basics = Cookies.get(COOKIES.BASICS);
    if (basics !== 'true') {
      Cookies.set(COOKIES.BASICS, true, { expires: 365 });
    }
    Object.keys(this.state).forEach((stateKey) => {
      const cookie = Cookies.get(stateKey);
      this.state[stateKey] = cookie === 'true';
    });
    this.originalState = this.state;

    this.updateGoogleAnalytics();
  }

  handleResize() {
    this.isMobile = window.innerWidth <= PHABLET_BREAKPOINT;
    this.handleBannerText();
    if (!this.isMobile && this.activeMobileTooltipElement) {
      this.activeMobileTooltipElement.classList.remove('show');
      this.activeMobileTooltipElement.parentNode.style.justifyContent = 'center';
      this.activeMobileTooltipElement = null;
    }
  }

  initBanner() {
    if (this.cookieBanner) {
      this.initBannerCookieSettingsButtonHandler();
      this.initBannerAcceptAllButtonHandler();
      this.initBannerDeclineButtonHandler();
      this.initBannerShowMoreButtonHandler();

      this.showCookieBanner();
    }
  }

  initBannerCookieSettingsButtonHandler() {
    // The Settings button opens the modal. As such if no modal exists, the listener is not required.
    if (this.modal) {
      el('#cookie-settings').addEventListener('click', () => {
        this.showCookieModal();
      });
    }
  }

  initBannerAcceptAllButtonHandler() {
    el('.cookies-accept-all').addEventListener('click', () => {
      this.setFeedbackGivenCookie(true);
      this.setSwitches(true);
      this.setCookiesFromStates();
      this.updateGoogleAnalytics();
      this.hideAllCookieElements();
    });
  }

  initBannerDeclineButtonHandler() {
    el('.cookies-decline').addEventListener('click', () => {
      this.setFeedbackGivenCookie(false);
      this.setSwitches(false);
      this.setCookiesFromStates();
      this.updateGoogleAnalytics();
      this.hideAllCookieElements();
    });
  }

  initBannerShowMoreButtonHandler() {
    el('#show-more').addEventListener('click', (event) => {
      event.stopPropagation();
      this.cookieBannerText.classList.toggle('cookie-text-mobile');
      this.cookieBannerText.classList.toggle('cookie-text-desktop');
    });
  }

  initModal() {
    if (this.modal) {
      this.setupSwitchesFromPreviousState();
      this.initModalSwitchChangeHandler();
      this.initModalCloseButtonHandler();
      this.initModalSaveSettingsButtonHandler();
    }
  }

  initModalSwitchChangeHandler() {
    Object.keys(this.stateSwitchElements).forEach((stateSwitchElementKey) => {
      this.stateSwitchElements[stateSwitchElementKey].addEventListener('change', () => {
        const isChecked = this.stateSwitchElements[stateSwitchElementKey].checked;
        this.state[stateSwitchElementKey] = isChecked;
      });
    });
  }

  initModalCloseButtonHandler() {
    el('.cookie-modal-wrapper .cookie-modal-close').addEventListener('click', () => {
      this.revertSwitchesToOriginalState();
      this.hideCookieModal();
    });
  }

  initModalSaveSettingsButtonHandler() {
    el('.cookies-save-settings').addEventListener('click', () => {
      this.setFeedbackGivenCookie(true);
      this.setCookiesFromStates();
      this.updateGoogleAnalytics();
      this.hideAllCookieElements();
    });
  }

  updateGoogleAnalytics() {
    const hasGrantedGoogleAnalyticsConsent = this.state[COOKIES.STATISTICS];
    if (hasGrantedGoogleAnalyticsConsent) {
      const consentValue = hasGrantedGoogleAnalyticsConsent ? 'granted' : 'denied';
      function gtag() { dataLayer.push(arguments); }
      gtag('consent', 'update', {
        ad_user_data: consentValue,
        ad_personalization: consentValue,
        ad_storage: consentValue,
        analytics_storage: consentValue
      });
    }
  }

  setCookiesFromStates() {
    Object.keys(this.state).forEach((stateKey) => {
      const cookieAccepted = this.state[stateKey]
      if (cookieAccepted) {
        Cookies.set(stateKey, this.state[stateKey], { expires: 365 });
      } else {
        Cookies.remove(stateKey);
      }
    });
  }

  /**
   * If no consent was given, ask again after half a day.
   * @param consentGiven
   */
  setFeedbackGivenCookie(consentGiven) {
    // Do not update the expiration time in case the cookie already exists.
    if (!this.hasGivenCookiesFeedback()) {
      if (consentGiven) {
        Cookies.set(COOKIES.FEEDBACK, true, { expires: 365 });
      } else {
        Cookies.set(COOKIES.FEEDBACK, true, { expires: 0.5 });
      }
    }
  }

  hasGivenCookiesFeedback() {
    const cookiesFeedback = Cookies.get(COOKIES.FEEDBACK);
    const hasCookiesFeedback = cookiesFeedback !== undefined;
    return hasCookiesFeedback && cookiesFeedback === 'true';
  }

  setupSwitchesFromPreviousState() {
    Object.keys(this.state).forEach((stateKey) => {
      const elSwitch = this.stateSwitchElements[stateKey];
      if (elSwitch) {
        if (this.state[stateKey] !== elSwitch.checked) {
          elSwitch.click();
        }
      }
    });
  }

  setSwitches(isChecked) {
    Object.keys(this.stateSwitchElements).forEach((switchElementKey) => {
      if (this.state[switchElementKey] !== isChecked) {
        this.stateSwitchElements[switchElementKey].click();
      }
    });
  }

  /**
   * Reverts switches to starting state.
   * Switches Listeners update {@link state}.
   */
  revertSwitchesToOriginalState() {
    Object.keys(this.stateSwitchElements).forEach((switchElementKey) => {
      if (this.state[switchElementKey] !== this.originalState[switchElementKey]) {
        this.stateSwitchElements[switchElementKey].click();
      }
    });
  }

  showCookieModal() {
    el('html').classList.add('disable-scroll');
    this.modal.classList.add('show');
  }

  hideCookieModal() {
    el('html').classList.remove('disable-scroll');
    this.modal.classList.remove('show');
  }

  showCookieBanner() {
    this.cookieBanner.classList.remove('hidden');
  }

  hideCookieBanner() {
    this.cookieBanner.classList.add('hidden');
  }

  hideAllCookieElements() {
    this.originalState = this.state;
    this.hideCookieModal();
    this.hideCookieBanner();
  }


  handleBannerText() {
    if (this.isMobile) {
      this.cookieBannerText.classList.add('cookie-text-mobile');
      this.cookieBannerText.classList.remove('cookie-text-desktop');
    } else {
      this.cookieBannerText.classList.add('cookie-text-desktop');
      this.cookieBannerText.classList.remove('cookie-text-mobile');
    }
  }
}
