import { gsap } from "gsap";

export function animateScrollToSelectedOffer() {
    // perform auto scroll to offer container IF url param is a jump to a specific offer itm
    // the logic for ensuring that the selected offer item is at the top of the list is in the offer app
    const urlIsJumpToOfferDetail = location.search && location.search.includes("?offer=");
    if (!urlIsJumpToOfferDetail) {
        return;
    }

    const offerDetailContainers = document.querySelectorAll("section.offer-detail-container")
    if (!offerDetailContainers.length || offerDetailContainers?.length===0) {
        return;
    }

    gsap.to(window, {duration: 3.5, scrollTo: {y: offerDetailContainers[0]}, ease: "power4.out" });
}
