import Swiper, { Navigation, Scrollbar } from "swiper";
import "swiper/swiper-bundle.css";

export const setupSwiper = (selector, mode) => {
  let swiper;
  if (mode === "centered-loop") {
    const centeredSwiperContainers = document.querySelectorAll(selector);
    if (!centeredSwiperContainers.length) {
      return;
    }

    for (const container of centeredSwiperContainers) {
      swiper = new Swiper(container, {
        slidesPerView: "auto",
        centeredSlides: true,
        loop: true,
        spaceBetween: 16,
        breakpoints: {
          620: {
            spaceBetween: 25,
          },
          768: {
            spaceBetween: 40,
          },
        },
      });
    }
  } else if (mode === "full-screen-slide") {
    const fullScreenSwiperContainers = document.querySelectorAll(selector);
    if (!fullScreenSwiperContainers.length) {
      return;
    }

    for (const container of fullScreenSwiperContainers) {
      swiper = new Swiper(container, {
        centeredSlides: false,
        loop: false,
        modules: [Navigation, Scrollbar],
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: false,
          grabCursor: true,
          horizontalClass: "product-slider-scrollbar",
          dragClass: "swiper-scrollbar-drag product-slider-drag",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    }
  } else {
    throw new Error("Currently, only 'centered loop' and 'full screen slide' swipers are supported.");
  }
  return swiper;
};
