import { setupSwiper } from "./swiper-setup-util";
import { onLoad, el, selectElementsFromParent } from "./util/util";

onLoad(() => {
  const productSlider = el(".product-slider-wrapper");
  if(productSlider) {

    const slides = selectElementsFromParent(productSlider, ".swiper-slide");
    const contents = selectElementsFromParent(productSlider, ".slide__content");
    const editMode = isEditMode === "true";
    
    if (!editMode) {
      Array.from(contents).forEach((content, index) => {
        slides[index].innerHTML = content.innerHTML;
        content.innerHTML = "";
      });
    }

    const swiper = setupSwiper(".product-slider", "full-screen-slide");
  
    showActiveContent(swiper, contents);
    
    swiper.on("slideChangeTransitionStart", () => {
      showActiveContent(swiper, contents);
    });
}
});

function showActiveContent(swiper, contents) {
  Array.from(contents).forEach((content, index) => {
    if (content && swiper.activeIndex !== index) {
      content.classList.add("hidden");
    } else {
      content.classList.remove("hidden");
    }
  });
}
