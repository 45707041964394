import { gsap } from "gsap";

export function animateTexts() {
  const texts = document.querySelectorAll(".reveal-text");

  if (!texts.length) {
    return;
  }

  texts.forEach((text) => {
    gsap.from(text, { scrollTrigger: text, duration: 1.5, y: 40, ease: "sine.inOut", opacity: 0 });
  });
}