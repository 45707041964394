import { ScrollTrigger } from "gsap/ScrollTrigger";

// Instantiate the KpiCounter class
export function animateKpis() {
  const kpiContainer = document.querySelector(".counter-start-toggle");
  const kpis = document.querySelectorAll(".kpi");

  if (!(kpiContainer && kpis.length)) {
    return;
  }

  ScrollTrigger.create({
    trigger: kpiContainer,
    onEnter: initiateKpis,
  })

  function initiateKpis() {
    kpis.forEach((kpi) => {
      new KpiCounter(kpi);
    });
  }
}

const data = {
  config: { min: 0, max: 100, speed: 100, increment: 1, delay: 0 },
  attributes: { min: "data-counter-min", max: "data-counter-max", speed: "data-counter-speed", increment: "data-counter-increment", delay: "data-counter-delay" },
};

class Screen {
  render(value) {
    this.element.innerHTML = value;
  }
}

class KpiCounter extends Screen {
  constructor(element) {
    super();
    this.element = element;
    this.min =
        parseInt(this.element.getAttribute(data.attributes.min)) ||
        data.config.min;
    this.max =
        parseInt(this.element.getAttribute(data.attributes.max).replace(/[^0-9]/g, "")) ||
        data.config.max;
    this.speed =
        parseInt(this.element.getAttribute(data.attributes.speed)) ||
        data.config.speed;
    this.increment =
        parseInt(this.element.getAttribute(data.attributes.increment)) ||
        data.config.increment;
    this.delay =
        parseInt(this.element.getAttribute(data.attributes.delay)) ||
        data.config.delay;
    this.current = this.min;
    this.interval;
    this.initialize();
  }

  die() {
    clearTimeout(this.interval);
  }

  count() {
    if (this.current < this.max) {
      this.current += this.increment;
      this.render(this.addApostrophe(this.current));
    } else if (this.current >= this.max) {
      //intentional comparison of string with int
      if (this.element.getAttribute(data.attributes.max) == this.max) {
        this.render(this.addApostrophe(this.max));
      } else {
        this.render(this.element.getAttribute(data.attributes.max));
      }
      this.die();
    }
  }

  addApostrophe(number) {
    let curr = String(number);
    if (1000 <= this.current && this.current < 10000) {
      curr = curr.substr(0, 1) + "'" + curr.substr(1, 3);
    } else if (10000 <= this.current && this.current < 100000) {
      curr = curr.substr(0, 2) + "'" + curr.substr(2, 3);
    } else if (this.current >= 100000) {
      curr = curr.substr(0, 3) + "'" + curr.substr(3, 3);
    }
    return curr;
  }

  initialize() {
    if (this.delay > 0) {
      setTimeout(() => {
        this.interval = setInterval(this.count.bind(this), this.speed);
      }, this.delay);
    } else {
      this.interval = setInterval(this.count.bind(this), this.speed);
    }
    this.render(this.min);
  }
}