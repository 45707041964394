import { gsap } from 'gsap';


let openToggles = sessionStorage.getItem('openToggles') ? JSON.parse(sessionStorage.getItem('openToggles')) : [];

export const animateToggleList = () => {
    const toggleLists = document.querySelectorAll(".toggle-list-wrapper");
    if (toggleLists.length === 0) {
        return;
    }

    toggleLists.forEach((toggleList) => {
        // verify required elements present
        const toggle = toggleList.querySelector(".list-toggle");
        const toggleIcon = toggleList.querySelector(".toggle-icon");
        const itemContainer = toggleList.querySelector(".item-container");
        if (!(toggleIcon && itemContainer && toggle)) {
            return;
        }

        setupHeightOffsets(toggleList, toggle, itemContainer);
        toggle.addEventListener('click', () => enactToggle(toggleList, toggle, toggleIcon, itemContainer));
        if(openToggles.includes(toggle.id)) {
            openToggleList(toggleList, toggle, toggleIcon, itemContainer);
            // after re-opening old toggle state, we clear the item from the session-storage, as we do not want to save it "indefinitely".
            removeToggleState(toggle);
        }

        // When  navigates back to the page, the toggle is still open, however the state is cleared (see above). If the user now clicks on a link in the open toggle, we have to store the open state again.
        const linkChildren = itemContainer.getElementsByTagName('a');
        if (linkChildren.length > 0) {
            Array.from(linkChildren).forEach((link) => {
                link.addEventListener('click', () => {
                    storeToggleState(toggle);
                })
            });
        }

    })
}

const setupHeightOffsets = (toggleList, toggle, itemContainer) => {
    // offset the itemContainer by its own height in the y-axis, leading to an initially closed toggle list
    const itemContainerHeight = itemContainer.clientHeight;
    gsap.set(itemContainer, { y: -itemContainerHeight });
    // correct the height of the outermost wrapper of the toggleList to prevent unused whitespace which would remain
    // where the itemContainer initially was before its offset on the y-axis. In practice, this means simply setting the
    // height of the entire toggleList to that of the toggle button
    const toggleButtonHeight = toggle.clientHeight;
    gsap.set(toggleList, { height: toggleButtonHeight })
}

const enactToggle = (toggleList, toggle, toggleIcon, itemContainer) => {
    // check current state (open | closed)
    if (itemContainer.classList.contains('toggle-closed')) {
        openToggleList(toggleList, toggle, toggleIcon, itemContainer);
    } else {
        closeToggleList(toggleList, toggle, toggleIcon, itemContainer);
    }
}

const openToggleList = (toggleList, toggle, toggleIcon, itemContainer) => {

    itemContainer.classList.remove('toggle-closed');

    // expand toggleList to full size -> button height + item height
    const itemContainerHeight = itemContainer.clientHeight;
    const toggleButtonHeight = toggle.clientHeight;
    const totalExpandedToggleListHeight = itemContainerHeight + toggleButtonHeight;

    storeToggleState(toggle);

    gsap.timeline()
        .addLabel('icon')
        .to(toggleIcon, { duration: 0.1, rotate: 180 }, 'icon')
        .addLabel('sizing')
        .to(itemContainer, { duration: 0.1, y: 0, ease: "power2.out"  }, 'sizing')
        .to(toggleList, { duration: 0.1, height: totalExpandedToggleListHeight, ease: "power2.out"  }, 'sizing')
}

const closeToggleList = (toggleList, toggle, toggleIcon, itemContainer) => {

    itemContainer.classList.add('toggle-closed');

    // same logic as when doing the initial height offsets ('setupHeightOffsets')
    const itemContainerHeight = itemContainer.clientHeight;
    const toggleButtonHeight = toggle.clientHeight;

    removeToggleState(toggle);

    gsap.timeline()
        .addLabel('icon')
        .to(toggleIcon, { duration: 0.1, rotate: 0 }, 'icon')
        .addLabel('sizing')
        .to(itemContainer, { duration: 0.1, y: -itemContainerHeight, ease: "power2.in"  }, 'sizing')
        .to(toggleList, { duration: 0.1, height: toggleButtonHeight, ease: "power2.in"  }, 'sizing')
}

const storeToggleState = (toggle) => {
    const index = openToggles.findIndex(item => item.includes(toggle.id));
    if (index < 0) { // we do not want duplicates
        openToggles.push(toggle.id);
        sessionStorage.setItem('openToggles', JSON.stringify(openToggles));
    }
}
const removeToggleState = (toggle) => {
    const indexToRemove = openToggles.findIndex(item => item.includes(toggle.id));
    indexToRemove !== -1 && openToggles.splice(indexToRemove, 1);

    if (openToggles.length > 0) {
        sessionStorage.setItem('openToggles', JSON.stringify(openToggles));
    } else {
        // if the array is empty, we can remove it
        sessionStorage.removeItem('openToggles');
    }
}

