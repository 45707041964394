import { gsap } from "gsap";

export function animateReferenceCircleOnImage() {
  const referenceCircle = document.querySelector(".reference-circle");
  const referenceParent = document.querySelector(".reference");

  if (!(referenceCircle && referenceParent)) {
    return;
  }

  let defaultScreenWidth = 0;
  adjustCircleSizeToScreenSize();
  window.addEventListener("resize", adjustCircleSizeToScreenSize);

  // when configuring the "speed" (i.e., how much to scroll until animation is complete), refer to this video
  // https://vimeo.com/422191943 and the section '"start" and "end"', and use 'markers': true in the scrollTrigger
  // for visualization aid.

  /*
  animation starts instantly when scrolling:
    start: "center center" -> center of circle at center of screen, which is the case at the "start state" of the page
  animation "duration" controlled by total travel distance:
    end: "bottom 90%" -> animation terminates when bottom edge of the circle passes at 90% (from top) of the viewport.
              keep in mind that the circle has 150vh, so the total travel distance is larger than one might
              intuitively think (total of 35vh)
  */
  gsap.to(referenceCircle, {
    scrollTrigger: {
      trigger: referenceCircle,
      start: "center center",
      end: "bottom 90%",
      pin: referenceParent,
      scrub: 1,
      // markers: true
    },
    height: 0,
    width: 0,
  })

  function adjustCircleSizeToScreenSize() {
    if (window.innerWidth !== defaultScreenWidth) {
      defaultScreenWidth = window.innerWidth;
      if (window.innerHeight > window.innerWidth) {
        gsap.set(referenceCircle, { height: "150vh", width: "150vh", boxShadow: "0 0 0 100vh #FFFFFF" });
      } else {
        gsap.set(referenceCircle, { height: "150vw", width: "150vw", boxShadow: "0 0 0 100vw #FFFFFF" });
      }
    }
  }
}
