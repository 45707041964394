import { setupSwiper } from "./swiper-setup-util";
import { onLoad } from "./util/util";

window.addEventListener("resize", setImageSize);

onLoad(() => {
  setImageSize();
  setupSwiper(".image-slider", "centered-loop");
});

function setImageSize() {
  const imageSlides = document.querySelectorAll(".image-slide");

  if (!imageSlides.length) {
    return;
  }

  if (window.innerWidth <= 375) {
    for (const slide of imageSlides) {
      slide.style.width = "250px";
    }
  } else if (window.innerWidth <= 768) {
    for (const slide of imageSlides) {
      slide.style.width = 66 - 0.056 * (window.innerWidth - 375) + "%";
    }
  } else {
    for (const slide of imageSlides) {
      slide.style.width = "44%";
    }
  }
}
