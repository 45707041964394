import { el, onLoad } from './util/util';

const CONTACT_CTA_ID = '#contact-cta';

onLoad(() => {
  const cta = el(CONTACT_CTA_ID);

  if (!cta) {
    return;
  }

  let defaultScreenWidth = 0;
  setPosition();
  window.addEventListener("resize", setPosition);

  function setPosition() {
    if (window.innerWidth !== defaultScreenWidth) {
      defaultScreenWidth = window.innerWidth;
      const currentPosition = window.innerHeight / 10;
      cta.style.bottom = currentPosition + "px";
    }
  }
});
