import { gsap } from "gsap";

export function animateOfferImages() {
  const offers = Array.from(document.querySelectorAll(".offer"));

  if (!offers.length) {
      return;
  }

  // don't hide the first offer, remove it from offers array and run separate timeline directly on load
  const firstOffer = offers.shift();
  const firstOfferImage = firstOffer.querySelector(".offer-image");
  const firstOfferText = firstOffer.querySelector(".offer-text");
  const firstOfferBackground = firstOffer.querySelector(".offer-background");

  if (!(firstOfferImage && firstOfferText && firstOfferBackground)) {
    return;
  }

  const firstOfferTimeline = gsap.timeline();
  firstOfferTimeline
      .addLabel("start")
      .from(firstOfferBackground, { duration: 0.75, yPercent: 100, ease: "power3.in" })
      .addLabel("fadeInContent")
      .to(firstOfferBackground, { duration: 1, yPercent: -101, ease: "power3.out" })
      .from(firstOfferImage,{ duration: 1, scale: 1.5, yPercent: 25, ease: "power3.out", opacity: 0 },"fadeInContent")
      .from(firstOfferText,{ duration: 1.75, yPercent: 40, ease: "sine.inOut", opacity: 0 },"start");
  firstOfferTimeline.play()


  // for all the others
  offers.forEach((offer) => {
    const offerImage = offer.querySelector(".offer-image");
    const offerText = offer.querySelector(".offer-text");
    const offerBackground = offer.querySelector(".offer-background");

    if (!(offerImage && offerText && offerBackground)) {
      return;
    }

    const offerTimeline = gsap.timeline({ scrollTrigger: { trigger: offer, start: "top 40%" } });

    offerTimeline
      .addLabel("start")
      .from(offerBackground, { duration: 0.75, yPercent: 100, ease: "power3.in" })
      .addLabel("fadeInContent")
      .to(offerBackground, { duration: 1, yPercent: -101, ease: "power3.out" })
      .from(offerImage,{ duration: 1, scale: 1.5, yPercent: 25, ease: "power3.out", opacity: 0 },"fadeInContent")
      .from(offerText,{ duration: 1.75, yPercent: 40, ease: "sine.inOut", opacity: 0 },"start");
  });
}
