import { gsap } from "gsap";

export function animateTeaserCoverFlow() {
  const teaser = document.querySelector(".teaser");
  const intervalToggle = document.querySelector(".screen-size-desktop-toggle");
  const cards = document.querySelectorAll(".teaser-card");
  const cardContainer = document.querySelector(".card-container");
  const cardTitleContainers = document.querySelectorAll(".card-title-container");
  const cardTitles = document.querySelectorAll(".card-title");
  const cardTexts = document.querySelectorAll(".card-text");
  const smallSlider = document.querySelector(".slider-small");
  const largeSlider = document.querySelector(".slider-large");

  if (!(teaser && intervalToggle && cards.length && cardContainer && cardTitles.length && cardTitleContainers.length && cardTexts.length && smallSlider && largeSlider)) {
    return;
  }

  const timeout = 10000;
  let counter = 1;
  let intervalId;
  let cardTitleHeights = [];
  const cardHeights = [];

  updateContent()
  window.addEventListener("resize", updateContent);

  cards.forEach((card, index) => {
    if (index !== 0) {
      gsap.set(cardTitles[index], { autoAlpha: 0 });
      gsap.set(cardTexts[index], { autoAlpha: 0 });
    }
    card.onclick = () => {
      const place = parseInt(card.dataset.cardPlace);
      if (place === 2) {
        shift(1, index);
      } else if (place === 3) {
        shift(2, index);
      }
    }
  })

  function shift(step, index) {
    clearInterval(intervalId);
    let first;
    if (step === 1) {
      counter = (counter + 1)%cards.length;
      first = ((index-1)+cards.length)%cards.length;
      const last = (index+1)%cards.length;

      animateCoverflow(1, first, index, last);
      adjustCardPlace(index, last, first);

    } else if (step === 2) {
      counter = (counter + 2)%cards.length;
      first = ((index-2)+cards.length)%cards.length;
      const middle = ((index-1)+cards.length)%cards.length;

      animateCoverflow(2, first, middle, index);
      adjustCardPlace(index, first, middle);
    } else {
      throw new Error("Teaser component only supports 3 cards.");
    }

    startTitleTimeline(first, index);
    animateSlider(index);
    setIntervalIfScreenIsDesktop();
  }

  function startTitleTimeline(first, index) {
    const minHeightOfSelectedCard = getMinHeightOfSelectedCard(first, index);
    gsap.timeline()
        .to(cardTitles[first], { duration: 0.5, autoAlpha: 0 })
        .call(editHiddenClass,["add", cardTitleContainers[first]])
        .addLabel("contentSwitch")
        .call(editHiddenClass,["remove", cardTitleContainers[index]])
        .to(cardTitles[index], { duration: 0.5, autoAlpha: 1 })
        .to(teaser, { duration: 0.5, minHeight: minHeightOfSelectedCard }, "contentSwitch");
  }

  function editHiddenClass(action, target) {
    if (action === "add") {
      target.classList.add("hidden");
    } else if (action === "remove") {
      target.classList.remove("hidden");
    } else {
      throw new Error("Unsupported edit action on hidden class.");
    }
  }

  function animateCoverflow(step, first, middle, last) {
    if (step === 1) {
      gsap.timeline()
          .addLabel("startCoverFlow")
          .to(cards[first], { duration: 0.25, width: "83.33%", height: cardHeights[0] + "px", left: "-33.33%", zIndex: 40, opacity: 0 })
          .set(cardTexts[first], { autoAlpha: 0 }, "startCoverFlow")
          .addLabel("fadeInFirstCardFromBack")
          .set(cards[first], { width: "16.66%", height: cardHeights[4] + "px", left: "100%", zIndex: 0, opacity: 0, })
          .to(cards[first], { duration: 0.75, width: "33.33%", height: cardHeights[3] + "px", left: "66.66%", zIndex: 10, opacity: 1 }, "fadeInFirstCardFromBack")
          .to(cardTexts[middle], {duration: 0.4, delay: 0.6, autoAlpha: 1 }, "startCoverFlow")
          .to(cards[middle], { duration: 1, width: "66.66%", height: cardHeights[1] + "px", left: 0, zIndex: 30 }, "startCoverFlow")
          .to(cards[last], { duration: 1, width: "50%", height: cardHeights[2] + "px", left: "33.33%", zIndex: 20 }, "startCoverFlow");
    } else if (step === 2) {
      gsap.timeline()
          .addLabel("startCoverFlow")
          .to(cards[first], { duration: 0.25, width: "83.33%", height: cardHeights[0] + "px", left: "-33.33%", zIndex: 40, opacity: 0 })
          .set(cardTexts[first], { autoAlpha: 0 }, "startCoverFlow")
          .addLabel("fadeInFirstCardFromBack")
          .to(cards[middle], { duration: 0.75, width: "83.33%", height: cardHeights[0] + "px", left: "-33.33%", zIndex: 40, opacity: 0 }, "startCoverFlow")
          .addLabel("fadeInSecondCardFromBack")
          .set(cards[first], { width: "16.66%", height: cardHeights[4] + "px", left: "100%", zIndex: 0, opacity: 0 }, "fadeInFirstCardFromBack")
          .to(cards[first], { duration: 0.75, width: "50%", height: cardHeights[2] + "px", left: "33.33%", zIndex: 20, opacity: 1 }, "fadeInFirstCardFromBack")
          .set(cards[middle], { width: "16.66%", height: cardHeights[4] + "px", left: "100%", zIndex: 0, opacity: 0 }, "fadeInSecondCardFromBack")
          .to(cards[middle], { duration: 0.25, width: "33.33%", height: cardHeights[3] + "px", left: "66.66%", zIndex: 10, opacity: 1 }, "fadeInSecondCardFromBack")
          .to(cardTexts[last], {duration: 0.4, delay: 0.6, autoAlpha: 1 }, "startCoverFlow")
          .to(cards[last], { duration: 1, width: "66.66%", height: cardHeights[1] + "px", left: 0, zIndex: 30 }, "startCoverFlow");
    } else {
      throw new Error("Teaser component only supports 3 cards.");
    }
  }

  function animateSlider(index) {
    if (index === 0) {
      gsap.to(smallSlider, { duration: 1, width: 0 });
      gsap.to(largeSlider, { duration: 1, left: 0 });
    } else if (index === 1) {
      gsap.to(smallSlider, { duration: 1, width: "33.33%" });
      gsap.to(largeSlider, { duration: 1, left: "33.33%" });
    } else if (index === 2) {
      gsap.to(smallSlider, { duration: 1, width: "66.66%" });
      gsap.to(largeSlider, { duration: 1, left: "66.66%" });
    } else {
      throw new Error("Teaser component only supports 3 cards.");
    }
  }

  function adjustCardPlace(one, two, three) {
    cards[one].dataset.cardPlace = "1";
    cards[two].dataset.cardPlace = "2";
    cards[three].dataset.cardPlace = "3";
  }

  function getMinHeightOfSelectedCard(first, index) {
    return screenIsDesktop() ? "auto" : "-=" + (cardTitleHeights[first] - cardTitleHeights[index]) + "px";
  }

  function screenIsDesktop() {
    return getComputedStyle(intervalToggle).display !== "none";
  }

  function updateContent() {
    setIntervalIfScreenIsDesktop();
    setCardHeight();
    setTeaserHeightIfScreenIsNotDesktop();
  }

  function setCardHeight() {
    cardHeights[0] = document.querySelector(".teaser-fade-out").clientWidth * 1.3;
    cards.forEach((card) => {
      const cardHeight = (card.clientWidth * 1.3);
      cardHeights[card.dataset.cardPlace] = cardHeight;
      gsap.set(card, { height: cardHeight + "px" });
    })
    cardHeights[4] = document.querySelector(".teaser-fade-in").clientWidth * 1.3;
  }

  function setIntervalIfScreenIsDesktop() {
    clearInterval(intervalId);
    if (screenIsDesktop()) {
      intervalId = setInterval(shift, timeout, 1, counter);
    }
  }

  function setTeaserHeightIfScreenIsNotDesktop() {
    gsap.set(teaser, { minHeight: "auto" });
    if (screenIsDesktop()) {
      gsap.set(cardContainer, { height: "auto" });
    } else {
      let cardHeight;
      cardTitleHeights = [];
      cards.forEach((card, index) => {
        if (parseInt(card.dataset.cardPlace) === 1) {
          cardHeight = cards[index].clientHeight;
          cardTitleHeights.push(cardTitles[index].clientHeight);
        } else {
          cardTitleHeights.push(getHiddenElementHeight(cardTitles[index]));
        }
      })

      gsap.set(cardContainer, { height: cardHeight + "px" });
      gsap.set(teaser, { minHeight: teaser.clientHeight + "px" });
    }
  }

  function getHiddenElementHeight(element) {
    const clone = element.cloneNode(true);
    clone.style.cssText = `position: fixed; top: -100%; left: -100%; visibility: hidden; max-width: ${cardContainer.clientWidth}px`;
    document.body.append(clone);
    const height = clone.clientHeight;
    clone.remove();
    return height;
  }
}
