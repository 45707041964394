import {onLoad, els, selectClosestMatchingParentNode, selectElementFromParent} from './util/util';

onLoad(() => {
    try {
        const checkBoxes = els('.jls-checkbox');

        for (const cb of checkBoxes) {
            const input = cb.querySelector('input');
            input.addEventListener("click", e => onClick(e, input))
        }
    } catch(err) {
        console.error('Error during initialization of checkboxes', err);
    }
})

function onClick(event, inputElement) {
    // select closest parent form and id to set correct map entry of the consent map
    const parentForm = selectClosestMatchingParentNode(inputElement, 'form');
    if (parentForm) {
        const parentFormId = parentForm.id;
        const currentConsentState = formConsentMap.get(parentFormId).get(inputElement.id);
        formConsentMap.get(parentFormId).set(inputElement.id, {
            ...currentConsentState,
            isChecked: event.target.checked
        })
    }
    // disable error styling
    disableErrorStyling(inputElement);
}

function getErrorContainerFromInput(inputElement) {
    const parentCheckboxElement = selectClosestMatchingParentNode(inputElement, '.jls-checkbox');
    return selectElementFromParent(parentCheckboxElement, '.checkbox-error-container');
}

function disableErrorStyling(inputElement) {
    const errorContainerElement = getErrorContainerFromInput(inputElement);
    if (errorContainerElement) {
        errorContainerElement.classList.add('hidden');
    }
    inputElement.classList.remove('error');
}

export function handleCheckboxErrorOnSubmit(inputElement) {
    // add error styling
    const errorContainerElement = getErrorContainerFromInput(inputElement);
    if (errorContainerElement) {
        errorContainerElement.classList.remove('hidden');
    }
    inputElement.classList.add('error');
}
