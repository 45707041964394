import { gsap } from "gsap";

export function animateTiles() {
  const tileClass = ".tile";
  const tileFrontClass = ".tile-front";
  const tileBackClass = ".tile-back";
  const tiles = document.querySelectorAll(tileClass);
  const squares = document.querySelectorAll(".squared");

  if (!(tiles.length && squares.length)) {
    return;
  }

  setSquaresHeight();
  window.addEventListener("resize", setSquaresHeight);

  tiles.forEach((tile) => {
    const tileBack = tile.querySelector(tileBackClass);

    setAbove(tileBack);
    tile.addEventListener("click", (event) => {
      event.stopPropagation();
      fadeInBack(tile);
    });
    tile.addEventListener("mouseenter", () => { fadeInBack(tile) });
    tile.addEventListener("mouseleave", () => { fadeOutBack(tile) });
  })

  window.addEventListener("click", () => {
    fadeOutAll();
  });

  function setSquaresHeight() {
    squares.forEach((square) => {
      const squareSize = square.clientWidth + "px";
      gsap.set(square, { height: squareSize });
    })
  }

  function setAbove(tileBack) {
    gsap.set(tileBack, { yPercent: -101 });
  }

  function fadeInBack(tile) {
    const tileFront = tile.querySelector(tileFrontClass);
    const tileBack = tile.querySelector(tileBackClass);

    if (tileBack.dataset.backFadedIn === "false") {
      fadeOutAll();
      tileBack.dataset.backFadedIn = "true";
      gsap.to(tileFront, { duration: 0.5, yPercent: 101 });
      gsap.to(tileBack, { duration: 0.5, yPercent: 0 });
    }
  }

  function fadeOutBack(tile) {
    const tileFront = tile.querySelector(tileFrontClass);
    const tileBack = tile.querySelector(tileBackClass);

    tileBack.dataset.backFadedIn = "false";
    gsap.to(tileBack, { duration: 0.5, yPercent: -101 });
    gsap.to(tileFront, { duration: 0.5, yPercent: 0 });
  }

  function fadeOutAll() {
    tiles.forEach((tile) => {
      if (tile.querySelector(tileBackClass).dataset.backFadedIn === "true") {
        fadeOutBack(tile);
      }
    });
  }
}