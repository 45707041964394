
export const FRIENDLY_CAPTCHA_STATUS_UNSTARTED = ".UNSTARTED";
export const FRIENDLY_CAPTCHA_STATUS_FETCHING = ".FETCHING";
export const FRIENDLY_CAPTCHA_STATUS_UNFINISHED = ".UNFINISHED";

export const friendlyCaptchaGotSolution = (value) => {
  return value !== FRIENDLY_CAPTCHA_STATUS_UNSTARTED && 
    value !== FRIENDLY_CAPTCHA_STATUS_FETCHING && 
    value !== FRIENDLY_CAPTCHA_STATUS_UNFINISHED;
};

export const friendlyCaptchaUnstarted = (value) => {
  return value === FRIENDLY_CAPTCHA_STATUS_UNSTARTED;
};
