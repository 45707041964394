import {onLoad, selectClosestMatchingParentNode, selectElementFromParent} from "./util/util";

onLoad(() => {
  const toggles = document.querySelectorAll(".nav-menu-toggle");
  const body = document.querySelector("body");
  const menu = document.querySelector(".nav-menu");

  window.onbeforeunload = () => {
    // reset toggled state tracking upon navigation to prevent out-of-synch
    navIsToggledMobile[0] = true;
  }

  if (!(toggles.length && body && menu)) {
    return;
  }

  toggles.forEach((toggle) => {
    // Workaround. Hamburger nav icon animation to an X only works if rotation classes are initially defined.
    // remove classes again once:
    toggle.querySelectorAll("span")[0].classList.toggle("rotate-45");
    toggle.querySelectorAll("span")[1].classList.toggle("-rotate-45");
    toggle.querySelectorAll("span")[1].classList.toggle("-mt-3px");

    toggle.addEventListener("click", () => {
      body.classList.toggle("overflow-hidden");
      menu.classList.toggle("w-full");
      menu.classList.toggle("w-0");

      toggle.querySelectorAll("span")[0].classList.toggle("rotate-45");
      toggle.querySelectorAll("span")[1].classList.toggle("-rotate-45");
      toggle.querySelectorAll("span")[1].classList.toggle("-mt-3px");
          });

    toggle.addEventListener('click', () => {
      // click on burger to expand forces back-navigation to hide. mobile only, since burger only present
      // on mobile layouts.
      navIsToggledMobile[0] = !navIsToggledMobile[0];

      /*
      look for "neighbouring" back-navigation. expected html structure:

      <header>
        <div> -> regular navigation component
          <div>
            ...
            <button></button> -> this toggle, only present on mobile devices (md:hidden)
          </div>
        </div>
        <div> -> back navigation, may not be present, depends on config
          <nav></nav> -> set hidden class here to not create confilcts with any visibility settings acting on parent div
        </div>
      </header>

      */
      const pageHeader = selectClosestMatchingParentNode(toggle, "header");
      const backNavigationContainer = selectElementFromParent(pageHeader, "div.navigation-back-container");
      if (!backNavigationContainer) {
        return;
      }

      const backNav = selectElementFromParent(backNavigationContainer, "nav.navigation-back");
      if (!backNav) {
        return;
      }
      // have to set hidden class not on <div> (because already visibility/hidden logic applied to it) and also
      // not on <nav> (also already has hidden-based logic applied to it), so just hide the anchor
      // -> not exactly the cleanest approach, but it works without having to merge the visibility logic of all
      // the nested tags here.
      const backNavAnchor = selectElementFromParent(backNav, "a");
      if (backNavAnchor) {
        backNavAnchor.classList.toggle("hidden");
      }
    })

  });
});
