import { gsap } from "gsap";

export function animateScrollToServices() {
  const serviceAnchors = document.querySelectorAll(".story-service-anchor");
  const services = document.querySelectorAll(".story-service");

  if (!(serviceAnchors.length && services.length)) {
    return;
  }

  let servicesAvailable = []
  services.forEach((service) => {
    servicesAvailable.push(service.dataset.serviceType);
  });

  serviceAnchors.forEach((anchor) => {
    const serviceType = anchor.dataset.serviceType;
    if (servicesAvailable.includes(serviceType)) {
      const index = servicesAvailable.indexOf(serviceType);
      anchor.querySelector(".arrow").style.display = "block";
      anchor.classList.add("cursor-pointer");
      anchor.addEventListener("click", () => {
        gsap.to(window, { duration: 2, scrollTo: { y: services[index], offsetY: 60 }, ease: "power4.out" });
      });
    }
  });
}