import axios from 'axios';

export const contactsAxiosInstance = axios.create({
  baseURL: '/.rest/contacts/v1',
  headers: {
    'Cache-Control': 'no-store',
  },
});

export const googleAxiosInstance = axios.create({
  baseURL: '/.rest/google/v1',
});

export const friendlyAxiosInstance = axios.create({
  baseURL: '/.rest/friendlycaptcha/v1',
});
