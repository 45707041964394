import { el, selectElementFromParent, onLoad } from './util/util';

const CONTACT_MODAL_ID = '#contact-modal-wrapper';
const CONTACT_CTA_ID = '#contact-cta';
const CONTACT_MODAL_CLOSE_ID = '#contact-modal-close';
const CONTACT_IFRAME_ID = '#contact-form-modal-iframe';

onLoad(() => {
  const contactOverlay = new ContactOverlay();
  contactOverlay.initialize();
});

class ContactOverlay {
  show = false;

  initialize() {
    this.initializeElements();
    this.initContactCta();
    this.initModal();
  }

  initializeElements() {
    this.contactCta = el(CONTACT_CTA_ID);
    this.contactOverlay = el(CONTACT_MODAL_ID);
    this.contactFormIframe = el(CONTACT_IFRAME_ID);
  }

  initContactCta() {
    if (this.contactCta) {
      this.initContactCtaButtonHandler();
    }
  }

  initContactCtaButtonHandler() {
    if (this.contactCta) {
      this.contactCta.addEventListener('click', () => {
        this.showModal();
      });
    }
  }

  initModal() {
    if (this.contactOverlay) {
      this.initModalCloseButtonHandler();
    }
  }

  initModalCloseButtonHandler() {
    selectElementFromParent(this.contactOverlay, CONTACT_MODAL_CLOSE_ID).addEventListener('click', () => {
      this.hideModal();
    });
  }

  hideModal() {
    el('html').classList.remove('disable-scroll');
    this.contactOverlay.classList.remove('show');
    this.reloadIframe();
  }

  showModal() {
    el('html').classList.add('disable-scroll');
    this.contactOverlay.classList.add('show');
    this.reloadIframe();
  }

  reloadIframe() {
    const currentUrl = this.contactFormIframe.contentWindow.location.href;
    const originalUrl = this.contactFormIframe.src;
    if (originalUrl !== currentUrl) {
      this.contactFormIframe.src += '';
    }
  }
}
