import { onLoad } from "./util/util";

onLoad(() => {
    const links = document.querySelectorAll("a");

    if (!links.length) {
        return;
    }

    links.forEach((link) => {
        const textColor = getComputedStyle(link).color;
        if(textColor === 'rgb(255, 255, 255)') {
            link.classList.add('animate-black');
        } else {
            link.classList.add('animate-white');
        }
    });

});