import { onLoad } from "./util/util";

onLoad(() => {
  const toggle = document.querySelector(".header-video-toggle");
  const loopVideo = document.querySelector(".header-loop-video");
  const video = document.querySelector(".header-video");
  const container = document.querySelector(".header-video-container");

  if (!(loopVideo && container)) {
    return;
  }

  let defaultScreenWidth = 0;
  adjustContainerHeight();
  window.addEventListener("resize", adjustContainerHeight);

  window.addEventListener("click", () => {
    loopVideo.play();
  });

  if (toggle && video) {
    toggle.addEventListener("click", () => {
      if (video.requestFullscreen) {
        /* default */
        video.requestFullscreen();
      } else if (video.webkitRequestFullscreen) {
        /* Safari, Chrome, Edge, Opera */
        video.webkitRequestFullscreen();
      } else if (video.webkitSupportsFullscreen) {
        /* for mobile IOS */
        video.webkitEnterFullscreen();
        handleFullscreenChange(video);
      } else if (video.msRequestFullscreen) {
        /* IE11 */
        video.msRequestFullscreen();
      } else if (video.mozRequestFullScreen) {
        /* Firefox */
        video.mozRequestFullScreen();
      }
    });

    /* for mobile IOS */
    video.addEventListener("webkitendfullscreen", () => {
      handleFullscreenChange(video);
    });
    video.addEventListener("webkitbeginfullscreen", () => {
      handleFullscreenChange(video);
    });

    document.onfullscreenchange = () => {
      handleFullscreenChange(video);
    };
    document.onwebkitfullscreenchange = () => {
      handleFullscreenChange(video);
    };
    document.onmsfullscreenchange = () => {
      handleFullscreenChange(video);
    };
    document.onmozfullscreenchange = () => {
      handleFullscreenChange(video);
    };
  }

  function adjustContainerHeight() {
    if (window.innerWidth !== defaultScreenWidth) {
      defaultScreenWidth = window.innerWidth;
      container.style.height = window.innerHeight + "px";
    }
  }
});

function handleFullscreenChange(video) {
  if (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.webkitIsFullScreen ||
    document.msFullscreenElement ||
    document.mozFullScreenElement
  ) {
    unhideVideo(video);
  } else {
    hideVideo(video);
  }
}

function unhideVideo(video) {
  video.style.display = "block";
  video.controlsList = "nodownload";
  video.play();
}

function hideVideo(video) {
  video.pause();
  video.currentTime = 0;
  video.style.display = "none";
}
